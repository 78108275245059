$maxWidth: 1920;

@mixin adaptiv-font($pcSize, $mobSize) {
    $addSize: $pcSize - $mobSize;
    $addMobSize: $addSize + $addSize * 0.7;
    @media (max-width: 768px) {
        font-size: calc(#{$mobSize + px} + #{$addMobSize} * ((100vw - 320px) / #{$maxWidth}));
    }
    @media (min-width: 768px) {
        font-size: calc(#{$mobSize + px} + #{$addSize} * (100vw / #{$maxWidth}));
    }
}

.slider-main__title {
    @include adaptiv-font(48, 20);
}

