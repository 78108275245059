// @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

@include font-face("Montserrat", "../fonts/Montserrat-Thin", 100, normal);
@include font-face("Montserrat", "../fonts/Montserrat-ExtraLight", 200, normal);
@include font-face("Montserrat", "../fonts/Montserrat-Light", 300, normal);
@include font-face("Montserrat", "../fonts/Montserrat-Regular", 400, normal);
@include font-face("Montserrat", "../fonts/Montserrat-Medium", 500, normal);
@include font-face("Montserrat", "../fonts/Montserrat-SemiBold", 600, normal);
@include font-face("Montserrat", "../fonts/Montserrat-Bold", 700, normal);
@include font-face("Montserrat", "../fonts/Montserrat-ExtraBold", 800, normal);
@include font-face("Montserrat", "../fonts/Montserrat-Black", 900, normal);








